import React from 'react';
import styled from 'styled-components';
//import { Formik, Form } from 'formik';
//import * as Yup from 'yup';
//import Button from 'muicss/lib/react/button';

//import firebaseManager from 'firebaseManager.js'
import Appbar from 'components/Appbar.js';
import SocialLinks from 'components/SocialLinks.js';
//import SbsTextInput from 'forms/SbsTextInput.js';
//import SbsTextArea from 'forms/SbsTextArea.js';

const Content = styled.div`
  margin: 0;
  padding: 16px 32px;

  display: flex;
  justify-content: center;
  align-items: top;
`;

const Social = styled.div`
  padding-left: 20px;
`;

const Spacer = styled.div`
  height: 23px;
  margin-bottom: 4px;
`;


const ContactUs = ({sidebarDocked, sidebarButtonClicked}) => (
  <>
    <Appbar title="Contact Us" sidebarDocked={sidebarDocked} sidebarButtonClicked={sidebarButtonClicked}/>
    <Content>
      <div>
        <Social>
          <h2>Social Media:</h2>
          <SocialLinks/>
        </Social>
        <Spacer/>

        <iframe
          frameBorder="0"
          title="Contact Us"
          style={{ height:'460px', width: '100%', border: 'none' }}
          src='https://forms.zohopublic.com/kymberlispeight/form/ContactUs/formperma/omR9Wwivwv6sZ2yj3y80e8gEuEJ_vOAWxGvw6zhA2_o'>
        </iframe>

        {/**
        <h2>Send Kymberli a message<br/> or request her free gift:<br/> <i>Chapter 13 Lessons Learned</i></h2>
        <Spacer/>

        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            comments: '',
          }}
          validationSchema={Yup.object({
            firstName: Yup.string()
              .required('Required'),
            lastName: Yup.string()
              .required('Required'),
            email: Yup.string()
              .email('Invalid email address')
              .required('Required'),
            comments: Yup.string()
              .required('Required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            values['timestamp'] = new Date();
            values['source'] = 'Website'; // current URL?
            let db = firebaseManager.fb.firestore();
            let spkReqRef = db.collection('contact-comments');

            spkReqRef.add(values).then((docRef) => {
              alert("Request sent. Thank you!");
              setSubmitting(false);
            });
          }}
        >
          <Form>
            <SbsTextInput
              label="First Name*"
              name="firstName"
              placeholder="Jane"
            />
            <SbsTextInput
              label="Last Name*"
              name="lastName"
              placeholder="Doe"
            />
            <SbsTextInput
              label="Email*"
              name="email"
              type="email"
              placeholder="jane@formik.com"
            />
            <SbsTextArea
              label="Comments"
              name="comments"
            />

            <Button type="submit">Submit</Button>
          </Form>
        </Formik>
        **/}
      </div>
    </Content>
  </>
);

export default ContactUs;
