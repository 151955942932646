import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

// Init firebase
/*
let firebaseConfig = {
  apiKey: "AIzaSyDB6dDPkZZFnzysix5ubky1hixPxIxzhiQ",
  authDomain: "kym-speight-test.firebaseapp.com",
  projectId: "kym-speight-test",
  storageBucket: "kym-speight-test.appspot.com",
  messagingSenderId: "225395925422",
  appId: "1:225395925422:web:0a088608800579b747a83f",
  measurementId: "G-K9V6NNW2RD"
};
*/
let firebaseConfig = {
  authDomain: "kyberli-s-website.web.app",
  projectId: "kyberli-s-website",
  appId: "1:1044400429105:web:f3f04a9997814b296d1d10",
  storageBucket: "kyberli-s-website.appspot.com",
};
firebase.initializeApp(firebaseConfig);


let firebaseManager = {
  fb: firebase,

  firestoreGetObject: function(collectionName, id) {
    let db = firebaseManager.fb.firestore();

    return new Promise((resolve, reject) => {
      db.collection(collectionName).doc(id).get().then((doc) => {
        if (doc.exists) {
          resolve(doc.data());
        } else {
          reject('doc not found');
        }
      });
    });
  },

  firestoreCollectionToArray: function(collectionName) {
    let db = firebaseManager.fb.firestore();
    let preRecsRef = db.collection(collectionName);

    return new Promise((resolve, reject) => {
      preRecsRef.get().then((querySnapshot) => {
        let array = [];
        querySnapshot.forEach((doc) => {
          array.push(doc.data());
          if (array.length === querySnapshot.size) {
            resolve(array);
          }
        });
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  storageFilesList: function(path) {
    let array = [];
    let storageRef = this.fb.storage().ref();
    let listRef = storageRef.child(path);

    return new Promise((resolve, reject) => {
      listRef.listAll().then((res) => {
        /*
        res.prefixes.forEach((folderRef) => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
        });
        */
        res.items.forEach((itemRef) => {
          array.push(itemRef);
        });
        resolve(array);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

  storageFileGetDownloadUrl: function(fullPath) {
    let storageRef = this.fb.storage().ref();

    return new Promise((resolve, reject) => {
      storageRef.child(fullPath).getDownloadURL().then((url) => {
        //console.log(url);
        resolve(url);
      }).catch((ex) => {
        reject(ex);
      });
    });
  },

};

export default firebaseManager;
