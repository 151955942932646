import React from "react";
import styled from 'styled-components';

import Appbar from 'components/Appbar.js';
import Flex2ColWrap from 'components/Flex2ColWrap.js';

const TextContent = styled.div`
  flex: 1 1;
  min-width: 300px;
  max-width: 600px;
  margin-right: 16px;
  font-size: 17px;
`;

const VideoCont = styled.div`
  margin: 0 16px 32px;
`;

const WTHeader = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 32px;
`;

const StyP = styled.p`
  line-height: 1.2;
  clear: all;
`;

const Attribution = styled.p`
  margin-left: 60%;
  margin-bottom: 32px;
  line-height: 1.0;
`;

export default function SpeakerEndorsements(props) {

  // note that this does not currently recalculate upon browser resize
  const isWide = window.matchMedia("(min-width: 640px)").matches;
  const videoWidth = isWide ? 600 : 320;
  const videoHeight = isWide ? 338 : 180;

  return (
    <div>
      <Appbar title="Speaker Endorsements" sidebarDocked={props.sidebarDocked} sidebarButtonClicked={props.sidebarButtonClicked}/>

      <Flex2ColWrap>
        <VideoCont>
          <iframe src="https://player.vimeo.com/video/966778417?h=f2c8aca97c&byline=0&portrait=0"
            width={videoWidth} height={videoHeight} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
            title="Owner/Conference Organizer"
          >
          </iframe>
        </VideoCont>

        <VideoCont>
          <iframe src="https://player.vimeo.com/video/940932597?h=f2c8aca97c&byline=0&portrait=0"
            width={videoWidth} height={videoHeight} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
            title="NSA NW Chapter Testimonial"
          >
          </iframe>
        </VideoCont>

        <VideoCont>
          <iframe src="https://player.vimeo.com/video/911885758?h=9d00ba69c3&byline=0&portrait=0"
            width={videoWidth} height={videoHeight} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
            title="Audience Testimonials"
          >
          </iframe>
        </VideoCont>
      </Flex2ColWrap>

      <Flex2ColWrap>

        <TextContent>


          <WTHeader>Written Testimonials</WTHeader>

          <StyP>
            “Kymberli was a keynote speaker and held breakout sessions on networking. Not only is Kymberli an
engaging, dynamic speaker, she also makes networking seem so simple. I was able to meet and
connect with several women as a result of her sessions and I’ve continued the conversations outside
of the conference. I highly recommend Kymberli for any organization or individual that is looking to
build connection, collaboration or networking skills.”
          </StyP>
          <Attribution>
            &mdash;Christine Cascio
          </Attribution>

          <StyP>
            “Kymberli is an excellent presenter and brought the material to life even in a virtual setting. Kymberli
brought in interesting approach to relationship building to our Employee Resource Groups during our
annual event. She connects well with her audience, shares stories and examples, and provides
excellent expertise.”
          </StyP>
          <Attribution>
            &mdash;Reggie Willis
          </Attribution>

          <StyP>
            “Kymberli has a genuine talent for reminding us of the power of our relationships and all the ways we
can nurture them.
          </StyP>
          <StyP>
            In preparation for our recent Braun &amp; Gresham attorney retreat, she took the time to learn the
challenges each attorney had in building their own individual networks. She carefully crafted
examples and activities that challenged our team to enhance their listening skills, to engage
authentically, and tips to reinforce the right habits - even in this digital age.
          </StyP>
          <StyP>
            I highly recommend Kymberli to any executive or team that can benefit from the inspiration and
team-building that she brings. While the world of networking and building relationships seems
forever changed recently, Kymberli reminds all of us that - that with a little adaptation - we can
continue to grow our professional and personal connections.”
          </StyP>
          <Attribution>
            &mdash;Jeremy Humble
          </Attribution>

          <StyP>
            “I&#39;ve been lucky enough to serve this past year on a team led by Kymberli. She&#39;s one of the kindest,
most generous humans I&#39;ve had the pleasure of working with, and I feel blessed to have had the
opportunity to be a member of her team. Kymberli is servant-leadership personified.
          </StyP>
          <StyP>
            As a leader, a speaker, and a coach, she is an inspiration to so many people. As a witness to
Kymberli speaking many times since I first met her, she is always personable, professional, and
engaging. Whether you&#39;re looking to work with her as a trainer, keynoter, or serving on a board as I
have, you&#39;ll be amazed by Kymberli&#39;s talents.”
          </StyP>
          <Attribution>
            &mdash;Kara D. Kelley
          </Attribution>

          <StyP>
            “I heard Kymberli speak at the Maine Women&#39;s Conference, and enjoyed one of her sessions on
how to network. I learned so much from both and decided to take Kymberli&#39;s advice and challenge to
meet 15 people in 15 days. I jumped to the goal of 100 people in 100 days and finished early I found
such value in planting the seeds of connecting with others. I highly encourage you to engage with
Kymberli, hear her speak, and learn from her whenever possible!”
          </StyP>
          <Attribution>
            &mdash;Lorraine Connell
          </Attribution>


        </TextContent>

      </Flex2ColWrap>
    </div>
  );
}
