import React from "react";
import styled from 'styled-components';

import Appbar from 'components/Appbar.js';
import Flex2ColWrap from 'components/Flex2ColWrap.js';

const PageTitle = styled.div`
  font-weight: bold;
  font-size: 25px;
  margin-top: 32px;
  margin-bottom: -24px;
  text-align: center;
`;

const TextContent = styled.div`
  flex: 1 1;
  min-width: 324px;
  max-width: 512px;
  margin-top: 24px;
  margin-right: 32px;
  font-size: 20px;
`;

const Header = styled.p`
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 12px;
`;

const Para = styled.p`
  margin-bottom: 12px;
`;

const ImagesCol = styled.div`
  margin-top: 24px;
`;

const SpacedImg = styled.img`
  margin-top: 32px;
  display: block;
`;

const Workshops = ({sidebarDocked, sidebarButtonClicked}) => (
  <div>
    <Appbar title="Workshops/Breakouts" sidebarDocked={sidebarDocked} sidebarButtonClicked={sidebarButtonClicked}/>

    <PageTitle>Workshops/Breakouts</PageTitle>

    <Flex2ColWrap>
      <TextContent>

        <Header>The Leadership Mindset Workshops: 2-hour, half-day, and full-day</Header>

        <Para>
          Why do some leaders leave their mark and affect change while others just
          come and go? The skill to effectively lead plays a smaller role in this
          equation than one might think. The difference between being an effective
          leader and a transformational one is the leader’s mindset.
        </Para>

        <Para>
          In these programs, we explore building authentic partnerships and being in
          the pursuit of transformational ideas. Bringing these together sets the
          stage for developing innovative solutions.
        </Para>

        {/*
        <Para>
          Another aspect that can be addressed is the potential loneliness of leadership.
          While leaders are responsible for the vision of their organization and are
          “successful,” they quite often find themselves isolated. Senior Leaders often
          neglect their own well-being. Just like in flying a plane, when an individual
          puts their own oxygen mask on first, they are in a better position to help and
          lead their team. We address the whole person concept for great leadership.
        </Para>
        */}
        <Para><br/></Para>

        <Header>Speed Networking Conference Breakout</Header>

        <Para>
          This is a 1-hour event where attendees experience the art of having a great
          conversation through a guided exercise with someone they just met. The
          exercise is debriefed. Next, they get the opportunity to practice with 3
          additional people they meet during this session.
        </Para>

      </TextContent>

      <ImagesCol>
        <SpacedImg src={process.env.PUBLIC_URL + "/images/workshops-bg.jpg"} width="320px"/>
        <SpacedImg src={process.env.PUBLIC_URL + "/images/workshops-02.jpg"} width="320px"/>
        <SpacedImg src={process.env.PUBLIC_URL + "/images/workshops-04.jpg"} width="320px"/>
      </ImagesCol>

    </Flex2ColWrap>

  </div>
);

export default Workshops;
