import React from "react";
import styled from 'styled-components';
import Panel from 'muicss/lib/react/panel';
//import Button from 'muicss/lib/react/button';

import firebaseManager from 'firebaseManager.js'
import Appbar from 'components/Appbar.js';

const Content = styled.div`
  margin: 16px;
  padding: 0;
`;

const Title = styled.p`
  font-size: 18px;
  font-variant: bold;
  margin: 16px 0 8px 8px;
`;

const VidTitle = styled.p`
  font-size: 24px;
  font-variant: bold;
  margin: 0 0 24px 32px;
`;

const SPanel = styled(Panel)`
  /*background-color: #001B52;*/
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px solid #000;
  border-radius: 6px;
  max-width: 420px;
  @media screen and (min-width: 484px) {
    width: 420px;
  }
`;


class VideoContent extends React.Component {
  state = {
    fileRefs: [],
    videoData: null
  }

  componentDidMount() {
    const id = this.props.id.match.params.id;
    firebaseManager.firestoreGetObject('pre-recorded-training', id).then(object => this.setState({ videoData: object }));
    firebaseManager.storageFilesList('vidcontent/' + id).then((fileRefs) => {
      fileRefs.forEach((doc) => {
        firebaseManager.storageFileGetDownloadUrl(doc.fullPath).then(url => this.setState({ [doc.name]: url }));
      });
      this.setState({ fileRefs: fileRefs });
    });
  }

  renderFile(fileRef) {
    let url = this.state[fileRef.name];
    return (
      <SPanel key={ fileRef.name }>
        <a href={url} target="_blank" rel="noreferrer">{ fileRef.name }</a>
      </SPanel>
    );
  }

  renderFiles() {
    let panels = [];
    for (let fileRef of this.state.fileRefs) {
      panels.push(this.renderFile(fileRef));
    }
    return panels;
  }

  render() {
    if (!this.state.videoData) { return <div/> }
    return (
      <div>
        <Appbar title="Video Content" sidebarDocked={this.props.sidebarDocked} sidebarButtonClicked={this.props.sidebarButtonClicked}/>
        <Content>
          <Title>Supplementary content for Modular Training Video</Title>
          <VidTitle><a href={this.state.videoData.url} target="_blank" rel="noreferrer">{this.state.videoData.title}</a></VidTitle>
          { this.renderFiles() }
          { (this.state.fileRefs.length === 0) && (<Title>No files</Title>) }
        </Content>
      </div>
    );
  }
}

export default VideoContent;
