import React, { useState, useLayoutEffect } from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";

import SocialLinks from 'components/SocialLinks.js';

/* this works best for iPhone 6 or higher media query (375 x 667) */

const HomeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 640px;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 999px) {
    background-image: url(${process.env.PUBLIC_URL + "/images/speaking_maine_wc_2022-slim.jpg"});
    background-position: right top;
    background-repeat: repeat;
    background-size: auto 100%;
  }

  /*
    100vh is not great for mobile. it always reports as if the url bar is not shown.
    https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
    This forces no scrolling
  */
  position: fixed;
`;

const HomeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-height: 640px;
`;

const SocialLinksCont = styled.div`
  padding: 8px 16px;
`;

const TextSection = styled.div`
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  text-shadow: 2px 2px #000000;
  position: absolute;
  bottom: 0;
  left: 0;
  @media screen and (min-width: 1000px) {
    margin-left: 3vw;
  }
  @media screen and (max-width: 999px) {
    margin-left: 6vw;
  }
`;

const FlexDiv = styled.div`
  display: flex;
`;

const BigName = styled.div`
  @media screen and (min-width: 1000px) {
    font-size: 7vw;
  }
  @media screen and (max-width: 999px) {
    font-size: 12vw;
  }
  line-height: 1;
  /*font-stretch: expanded;*/
  font-weight: 800;
  transform: scaleY(0.9);  /* make it a bit fatter */
  transform-origin: 0 0;
  letter-spacing: 4px;

  text-shadow: 2px 2px #000000;
`;

const MenuIconSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 1vw;

  @media screen and (min-width: 1000px) {
    font-size: 3vw;
  }
  @media screen and (max-width: 999px) {
    font-size: 5vw;
  }
  font-weight: 800;
  text-shadow: 2px 2px #000000;
`;

const LastName = styled(BigName)`
  margin-left: 7vw;
`;

const TheCC = styled.div`
  margin-left: 4vw;
  @media screen and (min-width: 1000px) {
    font-size: 2vw;
  }
  @media screen and (max-width: 999px) {
    font-size: 4vw;
  }
  font-weight: 800;
  text-shadow: 2px 2px #000000;
  margin-bottom: 32px;
`;

const FlexVertCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoImg = styled.img`
  @media screen and (min-width: 1000px) {
    margin-left: 2vw;
    height: 6vw;
  }
  @media screen and (max-width: 999px) {
    margin-left: 4vw;
    height: 10vw;
  }
`;

const MenuCont = styled.div`
  height: 380px;
`;

// copied from SidebarContent - make reusable?
const StyledLink = styled(Link)`
  color: #ddd;
  text-decoration: none;
  &:active,
  &:focus,
  &:hover,
  &:active:hover {
    text-decoration: none;
  }
  &:hover,
  &:active:hover {
    color: #fff;
  }
`;

const MenuList = styled.ul`
  list-style-type: none;
  padding-left: 5vw;
  @media screen and (min-width: 1000px) {
    //font-size: 2vw;
    font-size: 26px;
  }
  @media screen and (max-width: 999px) {
    //font-size: 4vw;
    font-size: 22px;
  }
  font-weight: 800;
`;

const SMenuItem = styled.li`
  margin-bottom: 4px;
`;

const NarrowScreenKnowYouCont = styled(FlexVertCenter)`
  @media screen and (min-width: 600px) {
    display: none;
  }
`;

const WideScreenKnowYouCont = styled(FlexVertCenter)`
  @media screen and (max-width: 599px) {
    display: none;
  }
`;

const KnowYouImg = styled.img`
  width: 160px;
`;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function MenuItem({route, text}) {
  return (
    <SMenuItem>
      <StyledLink to={route}>{text}</StyledLink>
    </SMenuItem>
  );
}

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [ mediaWidth, mediaHeight ] = useWindowSize();
  // fill the screen regardless of aspect ratio
  // currently does not attempt to center the video - i think we've have to calculate and set negative margins
  const mediaExtraWide = (mediaWidth / mediaHeight) > (16/9);
  const videoWidth = mediaExtraWide ? mediaWidth : mediaHeight * 16 / 9;
  const videoHeight = mediaExtraWide ? mediaWidth * 9 / 16 : mediaHeight;
  return (
    <HomeContainer>
    {/* don't run bg video on small/"skinny" devices */}
    { (mediaWidth >= 1000) &&
      <video autoPlay loop muted width={videoWidth} height={videoHeight}>
        <source src={process.env.PUBLIC_URL + "/vid/kym_speight_loop_720p.mp4"} type="video/mp4" />
      </video>
    }
    <HomeOverlay>
      <SocialLinksCont>
        <SocialLinks/>
      </SocialLinksCont>

      <TextSection>
        { menuOpen &&
          <NarrowScreenKnowYouCont>
            <a href="https://www.amazon.com/Need-Know-You-Ordinary-Extraordinary/dp/1640857613/" target="_blank" rel="noreferrer">
              <KnowYouImg src={process.env.PUBLIC_URL + "/images/i-need-to-know-you-transp-med.png"}/>
            </a>
          </NarrowScreenKnowYouCont>
        }
        <FlexDiv>
          <MenuIconSpan onMouseEnter={() => setMenuOpen(true)}>☰</MenuIconSpan>
          <BigName>Kymberli</BigName>
        </FlexDiv>
        <FlexDiv>
          <LastName>Speight</LastName>
          <FlexVertCenter>
            <StyledLink to='/reel'>
              <VideoImg src={process.env.PUBLIC_URL + "/images/video-play.png"}/>
            </StyledLink>
          </FlexVertCenter>
        </FlexDiv>
        <FlexDiv>
          <TheCC>The CONFERENCE CONNECTOR</TheCC>
        </FlexDiv>

        <MenuCont>
          { menuOpen &&
            <FlexDiv>
              <MenuList>
                <MenuItem route="/about" text="About"/>
                <MenuItem route="/speaking" text="Speaking"/>
                <MenuItem route="/workshops" text="Workshops/Breakouts"/>
                <MenuItem route="/endorsements" text="Speaker Endorsements"/>
                {/* <MenuItem route="/modular-training" text="Modular Training"/> */}
                <MenuItem route="/podcasts" text="Podcast Interviews"/>
                <MenuItem route="/event-planners" text="Event Planners"/>
                <MenuItem route="/contact-us" text="Contact Us"/>
              </MenuList>
              <WideScreenKnowYouCont>
                <a href="https://www.amazon.com/Need-Know-You-Ordinary-Extraordinary/dp/1640857613/" target="_blank" rel="noreferrer">
                  <KnowYouImg src={process.env.PUBLIC_URL + "/images/i-need-to-know-you-transp-med.png"}/>
                </a>
              </WideScreenKnowYouCont>
            </FlexDiv>
          }
        </MenuCont>
      </TextSection>

    </HomeOverlay>
    </HomeContainer>
  );
}

export default Home;

/*
      <VideoCont>
        <iframe width="100%" src="https://www.youtube.com/embed/2j5qVdUgCRA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" allowfullscreen></iframe>
      </VideoCont>


        <PictureSection>
          <SpeakingImg src={process.env.PUBLIC_URL + "/images/speaking_maine_wc_2022-med.jpg"}/>
          <a href="https://www.amazon.com/Need-Know-You-Ordinary-Extraordinary/dp/1640857613/" target="_blank" rel="noreferrer">
            <KnowYouImg src={process.env.PUBLIC_URL + "/images/i-need-to-know-you-transp-med.png"}/>
          </a>


          <LogoContainer>
            <Logo src={process.env.PUBLIC_URL + "/images/usaf.png"}/>
            <Logo src={process.env.PUBLIC_URL + "/images/ct-leaders.png"}/>
            <Logo src={process.env.PUBLIC_URL + "/images/ally-full.jpg"}/>
            <Logo src={process.env.PUBLIC_URL + "/images/ag-workforce-labor-solutions-290px.png"}/>
          </LogoContainer>
          <LogoContainer>
            <Logo src={process.env.PUBLIC_URL + "/images/braun-gresham.jpg"}/>
          </LogoContainer>
          <LogoContainer>
            <Logo src={process.env.PUBLIC_URL + "/images/st-eds.png"}/>
          </LogoContainer>
*/