import React from "react";
import styled from 'styled-components';
import Panel from 'muicss/lib/react/panel';

import firebaseManager from 'firebaseManager.js'
import Appbar from 'components/Appbar.js';

const Content = styled.div`
  margin: 0;
  padding: 0;
`;

const PanelsCont = styled.div`
  padding: 32px 32px 16px 32px;
  @media screen and (max-width: 484px) {
    padding: 32px 8px 16px 8px;
  }
`;

const SPanel = styled(Panel)`
  /*background-color: #001B52;*/
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px solid #000;
  border-radius: 6px;
  max-width: 420px;
  @media screen and (min-width: 484px) {
    width: 420px;
  }
`;

const PanelHead = styled.h2`
  margin: 0;
  margin-left: 24px;
  text-indent: -24px;
  line-height: 1;
  font-variant: small-caps;
`;


class Podcasts extends React.Component {
  state = {
    podcasts: []
  }

  componentDidMount() {
    firebaseManager.firestoreCollectionToArray('podcasts').then((podcasts) => {
      this.setState({ podcasts: podcasts });
    });
  }

  renderPanel(podcast) {
    return (
      <SPanel key={podcast.url}>
        <PanelHead><a href={podcast.url} target="_blank" rel="noreferrer">{podcast.title}</a></PanelHead>
      </SPanel>
    );
  }

  renderPanels() {
    let panels = [];
    for (let podcast of this.state.podcasts) {
      panels.push(this.renderPanel(podcast));
    }
    return panels;
  }

  render() {
    return (
      <div>
        <Appbar title="Podcast Interviews" sidebarDocked={this.props.sidebarDocked} sidebarButtonClicked={this.props.sidebarButtonClicked}/>
        <Content>
          <PanelsCont>
            { this.renderPanels() }
          </PanelsCont>
        </Content>
      </div>
    );
  }
}

export default Podcasts;
