import styled from 'styled-components';

const Flex2ColWrap = styled.div`
  margin: 32px 16px;
  @media screen and (max-width: 1100px) {
    margin: 32px 8px;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* debugging
    outline: 3px dashed red;
  */
`;

export default Flex2ColWrap;
