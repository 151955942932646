import React from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';

const TopImg = styled.img`
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
`;

const MenuList = styled.ul`
  /*flex: 1 1 auto;*/
  list-style-type: none;
  margin: 16px 0 16px 10px;
  padding: 0;
  font-size: 20px;
  transform: scale(0.95, 1);
  line-height: 1.1;
`;

const SMenuItem = styled.li`
  margin-bottom: 12px;
`;

const StyledLink = styled(Link)`
  color: #ddd;
  text-decoration: none;
  &:active,
  &:focus,
  &:hover,
  &:active:hover {
    text-decoration: none;
  }
  &:hover,
  &:active:hover {
    color: #fff;
  }
`;

function MenuItem({parentProps, route, text}) {
  return (
    <SMenuItem>
      <StyledLink onClick={parentProps.dismissSidebarIfNotDocked} to={route}>{text}</StyledLink>
    </SMenuItem>
  );
}

export default function SidebarContent(props) {
  return (
    <div>
      <TopImg src={process.env.PUBLIC_URL + "/images/speaking-to-people-crop.png"} width="200" height="56"/>
      <nav>
        <MenuList>
          <MenuItem parentProps={props} route="/" text="Home"/>
          <MenuItem parentProps={props} route="/about" text="About"/>
          <MenuItem parentProps={props} route="/speaking" text="Speaking"/>
          <MenuItem parentProps={props} route="/workshops" text="Workshops/Breakouts"/>
          <MenuItem parentProps={props} route="/endorsements" text="Speaker Endorsements"/>
          {/* <MenuItem parentProps={props} route="/modular-training" text="Modular Training"/> */}
          <MenuItem parentProps={props} route="/podcasts" text="Podcast Interviews"/>
          <MenuItem parentProps={props} route="/event-planners" text="Event Planners"/>
          <MenuItem parentProps={props} route="/contact-us" text="Contact Us"/>
        </MenuList>
      </nav>
    </div>
  );
}
