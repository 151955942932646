import React from "react";
import styled from 'styled-components';

import Appbar from 'components/Appbar.js';

const VidContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px auto;
`;

export default function SpeakerReelVideo(props) {
  const mediaWidth = window.innerWidth;
  const videoWidth = (mediaWidth > 1200) ? 1200 : mediaWidth;
  const videoHeight = videoWidth * 9 / 16;
  return (
    <div>
      <Appbar title="Speaker Reel" sidebarDocked={props.sidebarDocked} sidebarButtonClicked={props.sidebarButtonClicked}/>
      <VidContainer>
        <iframe src="https://player.vimeo.com/video/1026155994?badge=0&autopause=0&player_id=0&app_id=58479"
          width={videoWidth} height={videoHeight} frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="Kym Reel v5 (Standard).mp4">
        </iframe>
      </VidContainer>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
}
