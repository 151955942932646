import React from "react";

import styled from 'styled-components';

const Cont = styled.div`
  margin: 16px 0;
`;

const LinkImg = styled.img`
  margin: 0 8px;
  width: 24px;
  height: 24px;
`;

export default function SocialLinks(props) {
  return (
    <Cont>
      <a href="https://www.linkedin.com/in/kymberlispeight/" target="_blank" rel="noreferrer">
        <LinkImg src={process.env.PUBLIC_URL + "/images/linkedin.png"} alt="LinkedIn"/>
      </a>
      <a href="https://www.facebook.com/KymberliSpeightSpeaks" target="_blank" rel="noreferrer">
        <LinkImg src={process.env.PUBLIC_URL + "/images/facebook.png"} alt="Facebook"/>
      </a>
      <a href="https://instagram.com/kymberlispeight/" target="_blank" rel="noreferrer">
        <LinkImg src={process.env.PUBLIC_URL + "/images/instagram.png"} alt="Instagram"/>
      </a>
      <a href="https://www.amazon.com/Need-Know-You-Ordinary-Extraordinary/dp/1640857613/" target="_blank" rel="noreferrer">
        <LinkImg src={process.env.PUBLIC_URL + "/images/amazon-buy.png"} alt="View book at Amazon"/>
      </a>
      <a href="https://www.amazon.com/NEED-KNOW-YOU-Ordinary-Extraordinary-ebook/dp/B0842YX9HM/ref=tmm_kin_swatch_0" target="_blank" rel="noreferrer">
        <LinkImg src={process.env.PUBLIC_URL + "/images/kindle-buy.png"} alt="View book for Kindle"/>
      </a>
      <a href="https://www.barnesandnoble.com/w/i-need-to-know-you-kymberli-speight/1136185006?ean=9781640857612" target="_blank" rel="noreferrer">
        <LinkImg src={process.env.PUBLIC_URL + "/images/barnes-noble-buy.png"} alt="View book at Barnes & Noble"/>
      </a>
    </Cont>
  );
}
