import React from "react";
import styled from 'styled-components';

import firebaseManager from 'firebaseManager.js'

const Content = styled.div`
  margin: 0;
  padding: 0;
`;

const Section = styled.div`
  padding: 16px;
`;

const SPanel = styled.div`
  /*background-color: #001B52;*/
  border: 1px solid #aaa;
  border-radius: 6px;
  padding: 8px 16px;
  margin: 8px 0;
`;

const Cell = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 24px;
  max-width: 600px;
`;


class AdminFormSubmissionDump extends React.Component {
  state = {
    contactUsSubs: [],
    freeGiftReqSubs: [],
    speakingReqSubs: []
  }

  componentDidMount() {
    firebaseManager.firestoreCollectionToArray('contact-comments').then((subs) => {
      subs.sort((a,b) => b.timestamp - a.timestamp);
      this.setState({ contactUsSubs: subs });
    });
    firebaseManager.firestoreCollectionToArray('free-gift-requests').then((subs) => {
      subs.sort((a,b) => b.timestamp - a.timestamp);
      this.setState({ freeGiftReqSubs: subs });
    });
    firebaseManager.firestoreCollectionToArray('speaking-requests').then((subs) => {
      subs.sort((a,b) => b.timestamp - a.timestamp);
      this.setState({ speakingReqSubs: subs });
    });
  }

  renderContactUs(sub) {
    return (
      <SPanel key={sub.timestamp.seconds}>
        <Cell>
          {sub.firstName} {sub.lastName}<br/>
          {sub.email}<br/>
          {new Date(sub.timestamp.seconds * 1000).toLocaleString()}
        </Cell>
        <Cell>
          {sub.comments}
        </Cell>
      </SPanel>
    );
  }

  renderContactUsSubs() {
    let panels = [];
    for (let sub of this.state.contactUsSubs) {
      panels.push(this.renderContactUs(sub));
    }
    return panels;
  }

  renderGiftReq(sub) {
    return (
      <SPanel key={sub.timestamp.seconds}>
        <Cell>
          {sub.firstName} {sub.lastName}<br/>
          {sub.email}<br/>
          {new Date(sub.timestamp.seconds * 1000).toLocaleString()}
        </Cell>
      </SPanel>
    );
  }

  renderGiftReqs() {
    let panels = [];
    for (let sub of this.state.freeGiftReqSubs) {
      panels.push(this.renderGiftReq(sub));
    }
    return panels;
  }

  renderSpeakingReq(sub) {
    return (
      <SPanel key={sub.timestamp.seconds}>
        <Cell>
          {sub.firstName} {sub.lastName}<br/>
          {sub.company}<br/>
          {sub.phone}<br/>
          {sub.address}<br/>
          {sub.email}<br/>
          {new Date(sub.timestamp.seconds * 1000).toLocaleString()}<br/>
          (See possibly more info in Firestore Database)
        </Cell>
      </SPanel>
    );
  }

  renderSpeakingReqs() {
    let panels = [];
    for (let sub of this.state.speakingReqSubs) {
      panels.push(this.renderSpeakingReq(sub));
    }
    return panels;
  }

  render() {
    return (
      <div>
        <Content>
          <Section>
            <h2>Contact Us</h2>
            { this.renderContactUsSubs() }
          </Section>
          <Section>
            <h2>Speaking Requests</h2>
            { this.renderSpeakingReqs() }
          </Section>
          <Section>
            <h2>Free Gift Requests</h2>
            { this.renderGiftReqs() }
          </Section>
        </Content>
      </div>
    );
  }
}

export default AdminFormSubmissionDump;
