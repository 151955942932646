import React from "react";
import styled from 'styled-components';

import Appbar from 'components/Appbar.js';

const PageContainer = styled.div`
`;

const MenuCont = styled.div`
  display: flex;
  justify-content: right;
`;

const PageMenu = styled.div`
  margin: 32px 96px 0 32px;
`;

const MenuItem = styled.div`
  font-size: 28px;
  font-weight: bold;
`;

const FlexCont = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
`;

const WantLists = styled.div`
  margin: 16px 8px;
`;

const ListCont = styled.div`
  margin: 16px 8px 32px 8px;
`;

const ListTitle = styled.p`
  font-size: 24px;
  font-weight: 800;
`;

const List = styled.ul`
  font-size: 22px;
`;

const PhotoCont = styled.div`
  margin: 32px 8px;
`;

const PhotoCredit = styled.p`
  font-size: 12px;
  font-style: italic;
`;


const EventPlanners = ({sidebarDocked, sidebarButtonClicked}) => (
  <PageContainer>
    <Appbar title="Event Planners" sidebarDocked={sidebarDocked} sidebarButtonClicked={sidebarButtonClicked}/>

    <MenuCont>
      <PageMenu>
        <MenuItem>
          <a href="#photos">
            Photos
          </a>
        </MenuItem>
        <MenuItem>
          <a href={process.env.PUBLIC_URL + "/pdf/Kym_Speight_Corporate_Bio.pdf"} target="_blank" rel="noreferrer">
            Bio
          </a>
        </MenuItem>
      </PageMenu>
    </MenuCont>

    <FlexCont>

      <WantLists>

        <ListCont>
          <ListTitle>Conference attendees want…</ListTitle>
          <List>
            <li>Practical and easily applicable tips and information</li>
            <li>Interesting, interactive and relevant content</li>
            <li>To walk away feeling empowered</li>
          </List>
        </ListCont>

        <ListCont>
          <ListTitle>Clients want…</ListTitle>
          <List>
            <li>Hi value delivered in an engaging format</li>
            <li>A speaker that becomes a willing part of their team</li>
            <li>Positive impact from the event in their organization</li>
          </List>
        </ListCont>

        <ListCont>
          <ListTitle>Meeting Planners want…</ListTitle>
          <List>
            <li>Participants that look forward to their next conference / event</li>
            <li>A speaker that is easy to work with</li>
            <li>A speaker that is relatable, inspirational and can deliver the goods with humor</li>
          </List>
        </ListCont>

      </WantLists>

      <PhotoCont>
        <a href={process.env.PUBLIC_URL + "/images/Kymberli_Speight_seated.jpg"} target="_blank" rel="noreferrer">
          <img src={process.env.PUBLIC_URL + "/images/Kymberli_Speight_seated.jpg"} alt="View larger" height="480px"/>
        </a>
        {/*<PhotoCredit>xxxxxxxxxxxxxxxx</PhotoCredit>*/}
      </PhotoCont>

    </FlexCont>


    {/*<h2>Photos</h2>*/}

    <FlexCont id="photos">

      <PhotoCont>
        <a href={process.env.PUBLIC_URL + "/images/Kymberli_Speight_8-2023.jpg"} target="_blank" rel="noreferrer">
          <img src={process.env.PUBLIC_URL + "/images/Kymberli_Speight_8-2023.jpg"} alt="View larger" height="400px"/>
        </a>
        {/*<PhotoCredit>xxxxxxxxxxxxxxxx</PhotoCredit>*/}
      </PhotoCont>

      <PhotoCont>
        <a href={process.env.PUBLIC_URL + "/images/kym-headshot-mewc-800.jpg"} target="_blank" rel="noreferrer">
          <img src={process.env.PUBLIC_URL + "/images/kym-headshot-mewc-800.jpg"} alt="View larger" height="400px"/>
        </a>
        <PhotoCredit>Photo by <a href="https://www.whitneyjfox.com/" target="_blank" rel="noreferrer">Whitney J. Fox Photography</a></PhotoCredit>
      </PhotoCont>

    </FlexCont>

  </PageContainer>
);

export default EventPlanners;
